<template>
    <Layout header-class="header-left-align" nav-alignment="right">
        <div class="container" style="padding: 50px 0;">
            <div class="row">
                <div class="col-lg-12 mb--10"  >
                    <h3 style="color: #333;">法律声明</h3>
                    <p class="color-gray b2">1. 网站版权说明<br />
夜售科技官方网站（以下简称“本站”）上的内容，仅供您个人而非商业使用。对于内容中所含的版权和其他所有权声明，您应予以尊重并在其副本中予以保留。如果本站内容无权利声明，并不代表本站对其不享有权利，也不意味着本站不主张权利，您应根据诚信原则尊重该等内容的合法权益并进行合法使用。您不得以任何方式修改、复制、公开展示、公布或分发这些材料或者以其他方式把它们用于任何公开或商业目的。禁止以任何目的把这些材料用于其他任何网站或其他平面媒体或网络计算机环境。本站上的内容及编辑等形式均受版权法等法律保护，任何未经授权的使用都可能构成对版权、商标和其他法律权利的侵犯。如果您不接受或违反上述约定，您使用本站的授权将自动终止，同时您应立即销毁任何已下载或打印好的本站内容。<br />
2. 网站信息发布<br />
本站的信息按原样提供，不附加任何形式的保障，包括适销性、适合于特定目的或不侵犯知识产权的保障。此外，夜售也不保障本站信息的准确性和完整性。本站中的内容或在这些内容中介绍的产品、价格和配置等会随时变更，恕不另行通知。本站中的内容也可能已经过期，夜售不承诺更新它们。本站发布的信息可能是在您本地尚不能得到的产品、程序或服务，您可向当地的夜售业务联系人和代理商咨询。<br />
3.商标保护说明<br />
本站使用和显示的所有商标、标志皆属夜售科技（上海华夜信息科技有限公司）所有，但注明属于其他方拥有的商标、标志、商号除外。本站所载的任何内容不应被视作未经夜售科技或其他方书面许可，以暗示、不反对或其他形式授予使用前述任何商标、标志的许可或权利。未经事先书面许可，任何人不得以任何方式使用夜售科技名称及夜售科技的商标、标记。<br />
4.产品或服务须知<br />
由于互联网的国际性或无国界性，因此通过本站所提供信息的亦具有国际性，所以不是所有的在本站上所提到的产品或服务在您的国家或地区都提供，请联系当地的销售代表或代理商了解在您的国家或地区所提供的产品或服务。
5.用户相关信息说明<br />
除个人识别信息，其他任何您发送或邮寄给本站的材料、信息或联系方式 ( 以下统称信息 ) 均将被视为非保密和非专有。本站对这些信息不承担任何义务。同时您的提交行为如果没有特别声明时，可视为同意（或授权）：夜售科技及其授权人将可因商业或非商业的目的自由复制、透露、分发、合并和以其他方式利用这些信息和所有数据、图像、声音、文本及其他内容。您对本站的使用不得违背法律法规及公众道德，不得向或从本站邮寄或发送任何非法、威胁、诽谤、中伤、淫秽、色情或其他可能违法的材料。若相关人员对此信息的内容及影响提出确有证据的警告或异议，本站可随时删除该等信息或无中止该信息的网上浏览，而不必事先取得提交者的同意，亦无义务事后通知提交者，情况严重的，本站可采取注销该用户的措施。<br />
6. 网站下载软件的使用<br />
如果您从本站下载软件，在使用软件时要遵守该软件携带的软件许可协议中所有的许可条款。在您阅读并接受软件许可协议的各项条款之前，不得下载或安装这一软件。<br />
7. 链接第三方网站<br />
本站到第三方网站的链接仅作为一种方便服务提供给您。如果使用这些链接，您将离开本站。夜售科技没有审查过任何第三方网站，对这些网站及其内容不进行控制，也不负任何责任。如果您决定访问任何与本站链接的第三方网站，其可能带来的结果和风险全部由您自己承担。<br />
8. 责任限度<br />
夜售科技及其供应商或本站中提到的第三方对任何损害概不负责（包括但不限于损失的利润、损失的数据或业务中断而造成的损害），无论这些损害是否由于使用、或不能使用本站的结果、与本站链接的任何网站或者任何这类网站中包含的信息所引起，也不管它们是否有保障、合同、侵权行为或任何其它法律根据以及事前已得到这类损害可能发生的忠告。如果您由于使用本站的信息而导致需要对设备或数据进行维护、修理或纠正，您应意识到须自行承担由此而造成的所有费用。<br />
9. 一般原则<br />
夜售科技可能随时修改这些条款。您应经常访问本页面以了解当前的条款，因为这些条款与您密切相关。这些条款的某些条文也可能被本站中某些页面上明确指定的法律通告或条款所取代。
因本公告或使用本站所发生的争议适用中华人民共和国法律。<br />
因本公告或使用本站发生争议，应当协商解决，协商不成的，由夜售科技本部所在地法院解决。<br />
本公告的解释权及对本站使用的解释权夜售科技所有。</p>
                </div>
            </div>
        </div>

       
        <!-- End Blog Area -->
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Separator from '../components/elements/separator/Separator'
 

    export default {
        name: 'BusinessConsulting2',
        components: {
            Separator,
            Layout
        },
        data() {
            return {
                id: this.$route.params.id,
                textAlign: 'left',
                textAlign1: 'center',
                iconSize: "40",
                serviceStyle: 'service-style="service__style--1 bg-color-blackest mt--25',
                goodsData: ['24小时','夜间销售','一站式整合营销服务','人脸识别','人工智能','公司间合作无后顾之忧'],
            }
        },
        computed: {
        },
       
        
    }
</script>