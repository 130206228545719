<template>
    <Layout header-class="header-left-align" nav-alignment="right">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 bg-transparent " data-aos="zoom-out-up"
                            data-aos-delay="70" style="width 100%;height: 0;padding-bottom: 51.7%">
            <img class="w-100" src="../assets/images/yeso/devicedetail1.png" alt="About Images">
        </div>
        <!-- End Slider Area -->

        <!-- <Separator/> -->
        <div class="slider-area slider-style-1 bg-transparent ">
            <img class="w-100" src="../assets/images/yeso/devicedetail2.png" alt="About Images">
        </div>

        <div class="slider-area slider-style-1 bg-transparent ">
            <img class="w-100" src="../assets/images/yeso/devicedetail3.png" alt="About Images">
        </div>


       
        <!-- End Blog Area -->
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Separator from '../components/elements/separator/Separator'
 

    export default {
        name: 'BusinessConsulting2',
        components: {
            Separator,
            Layout
        },
        data() {
            return {
                id: this.$route.params.id,
                textAlign: 'left',
                textAlign1: 'center',
                iconSize: "40",
                serviceStyle: 'service-style="service__style--1 bg-color-blackest mt--25',
                goodsData: ['24小时','夜间销售','一站式整合营销服务','人脸识别','人工智能','公司间合作无后顾之忧'],
            }
        },
        computed: {
        },
       
        
    }
</script>