<template>
    <!-- Start Copy Right Area  -->
    <div class="copyright-area copyright-style-one">
        <div class="container">
            <div class="align-items-center" style="display: flex;justify-content:center;">
                <a style="text-align: center;color: #fff" 
        target="_blank" 
        href="https://beian.miit.gov.cn/#/Integrated/index">沪ICP备20024554号-1<span style="padding: 0 5px;">|</span></a>
        <router-link to="/explain" style="color: #fff"> 法律声明</router-link>
            </div>
        </div>
    </div>
    <!-- End Copy Right Area  -->
</template>

<script>
    export default {
        name: 'Copyright'
    }
</script>