<template>
    <Layout>
       <div class="slider-area slider-style-1 bg-transparent " data-aos="zoom-out-up"
                            data-aos-delay="70">
            <img class="w-100" src="../assets/images/yeso/news/bg.png" alt="About Images">
        </div>
        <div class="container" style="padding: 50px 0;">
                <div class="row">
                    <div class="col-lg-12 mb--10" style="text-align: center;">
                        <h3 style="color: #333;">合作伙伴</h3>
                        <p class="color-gray b1">已累计服务：2000+门店  40多个便利店连锁零售品牌  10多个知名消费品品牌</p>
                    </div>
                    <div class="slider-area slider-style-1 bg-transparent" style="max-width: 1000px;min-width: 400px;margin: auto;">
                          <vue-seamless-scroll
                            :data="logoData"
                            :class-option="seamlessScrollOption"
                            style="
                              max-width: 1000px;
                              min-width: 400px;
                              height: 420px;
                              overflow: hidden;
                            "
                          >
                          <ul style="width: 2060px;">
                            <li style="float:left;list-style-type:none;"><img  src="../assets/images/yeso/productLogo/logo1.png" width=226 height=420  alt=""></li>
                            <li style="float:left;list-style-type:none;"><img  src="../assets/images/yeso/productLogo/logo2.png" width=290 height=420 alt=""></li>
                            <li style="float:left;list-style-type:none;"><img  src="../assets/images/yeso/productLogo/logo3.png" width=293 height=420 alt=""></li>
                            <li style="float:left;list-style-type:none;"><img  src="../assets/images/yeso/productLogo/logo4.png" width=230 height=420 alt=""></li>
                            <li style="float:left;list-style-type:none;"><img  src="../assets/images/yeso/productLogo/logo5.png" width=322 height=420 alt=""></li>
                            <li style="float:left;list-style-type:none;"><img  src="../assets/images/yeso/productLogo/logo6.png" width=352 height=420 alt=""></li>
                            <li style="float:left;list-style-type:none;"><img  src="../assets/images/yeso/productLogo/logo7.png" width=311 height=420 alt=""></li>
                          </ul>
                            
                            <!-- <img  src="'../assets/images/yeso/productLogo/logo1.png'" width=100 alt=""> -->
                          </vue-seamless-scroll>
                        </div>
                </div>
            </div>
        <div class="rn-blog-area rn-section-gap">
            <div class="container">
                <div class="row mt_dec--30">
                    <div class="col-lg-12 mb--10" style="text-align: center;">
                        <h3 style="color: #333;">公司动态</h3>
                    </div>
                    <div class="col-lg-12">
                        <div class="row row--15">
                            <div class="col-lg-3 col-md-6 col-12  mt--30"
                                 data-aos="slide-up"
                                 data-aos-duration="800"
                                 :data-aos-delay="100 + index"
                                 v-for="(blog, index) in posts"
                                 :key="index">
                                <BlogPost :blog="blog" />
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-lg-12 text-center">
                        <div class="rwt-load-more text-center mt--60">
                            <a class="btn-default" href="#">
                                View More Post <Icon name="loader"/>
                            </a>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="rn-blog-area rn-section-gap">
            <div class="container">
                <div class="row mt_dec--30">
                    <div class="col-lg-12 mb--10" style="text-align: center;">
                        <h3 style="color: #333;">技术突破</h3>
                    </div>
                    <div class="col-lg-12" style="width: 80%;margin: auto;">
                        <div class="row row--15">
                            <div class="col-lg-12 mt--30">
                                 <div style="display: flex;align-items: center;">
                                    <img src="../assets/images/yeso/card1.png" width="240" alt="">
                                    <div style="margin-left: 250px;flex: 1;padding: 0 0 60px 0;border-bottom: 1px solid #ddd">
                                        <div style="font-size: 20px;">15项实用新型专利</div>
                                        <router-link to="/abouts/1" style="text-decoration: underline;color: #0A3AFF;font-size: 12px;">查看详情</router-link>
        
                                    </div>
                                 </div>
                            </div>
                            <div class="col-lg-12 mt--30">
                                <div style="display: flex;align-items: center;">
                                    <img src="../assets/images/yeso/card2.png" width="240" alt="">
                                    <div style="margin-left: 250px;flex: 1;padding: 0 0 60px 0;border-bottom: 1px solid #ddd">
                                        <div style="font-size: 20px;">超20项软件著作权</div>
                                        <router-link to="/abouts/2" style="text-decoration: underline;color: #0A3AFF;font-size: 12px;">查看详情</router-link>
        
                                    </div>
                                 </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Icon from '../components/icon/Icon'
    import BlogPost from '../components/blog/BlogPost'
    import Layout from '../components/common/Layout'
    import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
    import BlogPostMixin from '../mixins/BlogPostMixin'
    import VueSeamlessScroll from 'vue-seamless-scroll'

    export default {
        name: 'BlogListPage',
        components: {Icon, BlogPost, Layout, Breadcrumb,VueSeamlessScroll},
        mixins: [BlogPostMixin],
        data() {
            return {
                logoData: [1,2,3,4,5,6,7],
            }
        },
        computed: {
            seamlessScrollOption() {
              return {
                step: 0.8, // 数值越大速度滚动越快
                limitMoveNum: this.logoData.length, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 2, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
              };
            },
        },
    }
</script>