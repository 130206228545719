<template>
    <Layout  header-class="header-left-align" nav-alignment="right">
      <div class="slider-area slider-style-1 bg-transparent" data-aos="zoom-in-up"
                            data-aos-delay="70" style="width 100%;height: 0;padding-bottom: 37%">
            <router-link to='/productDetail'><img class="w-100" src="../assets/images/yeso/product/product.png" alt="About Images"></router-link>
        </div>
        <!-- <div class="slider-area slider-style-1 bg-transparent" data-aos="zoom-in-up"
                            data-aos-delay="70" style="min-height: 200px">
            <img class="w-100" src="../assets/images/yeso/product/product.png" alt="About Images">
        </div> -->

        <div class="slider-area slider-style-1 bg-transparent" data-aos="zoom-in-up"
                            data-aos-delay="70" style="width 100%;height: 0;padding-bottom: 50%">
            <router-link to='/productDetail'><img class="w-100" src="../assets/images/yeso/product/device.png" alt="About Images"></router-link>
        </div>
        <!-- End Slider Area -->

        <!-- Start Service Area -->
       <!-- <div class="rwt-about-area rn-section-gap">
            <div class="container">
                <div class="row row--30 align-items-center">
                    <div class="col-lg-5">
                        <div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
                            <img class="w-100" src="" alt="About Images">
                        </div>
                    </div>
                    <div class="col-lg-7 mt_md--40 mt_sm--40">
                        <div class="content">
                            <div class="section-title">
                                <h3 class="title" data-aos="fade-up" data-aos-delay="280">
                                    关于我们
                                </h3>
                                <p style="color: #999;" data-aos="fade-up" data-aos-delay="310">
                                    上海华夜信息科技有限公司，专注于智能化无人零售技术及运营解决方案服务，
                                    公司通过对三轴自动寻址技术及智能售货机终端技术的优化升级，以及结合零售终
                                    端个性化场景需求，独立研发并运营 “夜售”24H智能无人值守货架，为门店24H
                                    营业提供降本增效解决方案，公司领导团队在连锁便利体系、快消品牌公司及无人
                                    零售领域有十余年管理及运营经验，具有强大的软硬件自主研发能力及互联网线上
                                    线下整合能力，拥有20多项技术专利。
                                </p>
                                <div class="goodsData">
                                  <div class="item" v-for="(item,index) in goodsData" :key="index">
                                    <div class="icon"><div class="inner"></div></div>
                                    <div>{{item}}</div>
                                  </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- End Service Area -->
        <!-- <Separator/> -->
        <!-- Start Brand Area -->

           
        <!-- End Brand Area -->


       

        

        <!-- End Elements Area -->
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Separator from '../components/elements/separator/Separator'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import AboutFour from '../components/elements/about/AboutFour'
    import ServiceOne from '../components/elements/service/ServiceOne'
    import Timeline from '../components/elements/timeline/Timeline'
    import Counter from '../components/elements/counterUp/Counter'
    import Brand from '../components/elements/brand/Brand'
    import Team from '../components/elements/team/Team'

    export default {
        name: 'About',
        components: {Team, Brand, Counter, Timeline, ServiceOne, AboutFour, SectionTitle, Separator, Layout},
        data() {
            return {
                goodsData: ['24小时','夜间销售','一站式整合营销服务','人脸识别','人工智能','公司间合作无后顾之忧'],
                serviceStyle: 'service-style="service__style--1  mt--25',
                textAlign1: 'center',
            }
        },
        computed: {
            
        },
    }
</script>