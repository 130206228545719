<template>
    <div class="advance-pricing" data-aos="fade-up">
        <div class="inner">
            <div class="row row--0">
                <div class="col-lg-6">
                    <div class="pricing-left">
                        <h3 class="main-title">Active Plan Mode.</h3>
                        <p class="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo,
                            quisquam.</p>
                        <div class="price-wrapper">
                            <span class="price-amount">$129 <sup>/mo</sup></span>
                        </div>
                        <div class="pricing-btn-group">
                            <a href="#" class="btn-default">Purchase Now</a>
                            <a href="#" class="btn-default btn-border">Upgrade</a>
                        </div>
                        <div class="rating">
                            <a href="#rating">
                                <Icon name="star"/>
                            </a>
                            <a href="#rating">
                                <Icon name="star"/>
                            </a>
                            <a href="#rating">
                                <Icon name="star"/>
                            </a>
                            <a href="#rating">
                                <Icon name="star"/>
                            </a>
                            <a href="#rating">
                                <Icon name="star"/>
                            </a>
                        </div>
                        <span class="subtitle">rated 4.5/5 Stars in 1000+ reviews.</span>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="pricing-right">
                        <div class="pricing-offer">
                            <div class="single-list">
                                <h4 class="price-title">Advance Plans You can Get.</h4>
                                <ul class="plan-offer-list">
                                    <li>
                                        <Icon name="check" size="18"/>
                                        5 PPC Campaigns
                                    </li>
                                    <li>
                                        <Icon name="check" size="18"/>
                                        Digital Marketing
                                    </li>
                                    <li>
                                        <Icon name="check" size="18"/>
                                        Marketing Agency
                                    </li>
                                    <li>
                                        <Icon name="check" size="18"/>
                                        Seo Friendly
                                    </li>
                                </ul>
                            </div>
                            <div class="single-list mt--40">
                                <h4 class="price-title">Basic Plans Uou can Get.</h4>
                                <ul class="plan-offer-list">
                                    <li>
                                        <Icon name="check" size="18"/>
                                        5 PPC Campaigns
                                    </li>
                                    <li>
                                        <Icon name="check" size="18"/>
                                        Digital Marketing
                                    </li>
                                    <li>
                                        <Icon name="check" size="18"/>
                                        Marketing Agency
                                    </li>
                                    <li>
                                        <Icon name="check" size="18"/>
                                        Seo Friendly
                                    </li>
                                    <li>
                                        <Icon name="check" size="18"/>
                                        App Development
                                    </li>
                                    <li>
                                        <Icon name="check" size="18"/>
                                        Social Development
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Icon from '../../icon/Icon'

    export default {
        name: 'AdvancePricing',
        components: {Icon}
    }
</script>