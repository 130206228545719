<template>
    <Layout header-class="header-left-align" nav-alignment="right">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 bg-transparent " v-if="id== 1" data-aos="zoom-out-up"
                            data-aos-delay="70" style="width 100%;height: 0;padding-bottom: 60%">
            <img class="w-100" src="../assets/images/yeso/product1.png" alt="About Images">
        </div>
        <!-- End Slider Area -->

        <!-- <Separator/> -->
        <div class="slider-area slider-style-1 bg-transparent " style="width 100%;height: 0;padding-bottom: 69%" v-if="id == 2">
            <img class="w-100" src="../assets/images/yeso/product2.png" alt="About Images">
        </div>


       
        <!-- End Blog Area -->
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
 
    export default {
        name: 'BusinessConsulting2',
        components: {
            Layout
        },
        data() {
            return {
                id: this.$route.params.id,
                textAlign: 'left',
                textAlign1: 'center',
                iconSize: "40",
                goodsData: ['24小时','夜间销售','一站式整合营销服务','人脸识别','人工智能','公司间合作无后顾之忧'],
            }
        },
        computed: {
            
        },
       
        
    }
</script>