<template>
    <Layout>
        <!-- Start Accordion Area -->
        <div class="rn-accordion-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 offset-lg-1">
                        <SectionTitle
                            class="mb--30"
                            text-align="center"
                            subtitle="Accordion"
                            title="Customize All"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <div class="row mt--35 row--20">
                    <div class="col-lg-10 offset-lg-1">
                        <Accordion
                            id="accordionExampleOne"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
            </div>
        </div>
        <!-- End Accordion Area -->

        <Separator/>

        <!-- Start Accordion Area -->
        <div class="rn-accordion-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 offset-lg-1">
                        <SectionTitle
                            class="mb--30"
                            text-align="center"
                            subtitle="Accordion"
                            title="Customize All"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <div class="row mt--35 row--20">
                    <div class="col-lg-10 offset-lg-1">
                        <Accordion
                            id="accordionExampleTwo"
                            accordion-style-class="rn-accordion-02"
                            :accordion-content="[
                                {
                                    id: 'Five',
                                    title: 'What is Doob ? How does it work?',
                                    description: 'You can run doob easily. First You\'ll need to have node and npm on your machine. So Please open your command prompt then check your node -v and npm -v Version. Goes To Your your command prompt: then First: npm install At Last: npm run start. By the following way you can be run your project easily.'
                                },
                                {
                                    id: 'Six',
                                    title: 'How can I get the customer support?',
                                    description: 'After purchasing the product need you any support you can be share with us with sending mail to rainbowit10@gmail.com.'
                                },
                                {
                                    id: 'Seven',
                                    title: 'Can I get update regularly and For how long do I get updates?',
                                    description: 'Yes, We will get update the Trydo. And you can get it any time. Next time we will comes with more feature. You can be get update for unlimited times. Our dedicated team works for update.'
                                },
                                {
                                    id: 'Eight',
                                    title: 'How can I run doob html template?',
                                    description: 'You can run doob easily. First You\'ll need to have node and npm on your machine. So Please open your command prompt then check your node -v and npm -v Version. Goes To Your your command prompt: then First: npm install At Last: npm run start. By the following way you can be run your project easily.'
                                },
                            ]"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
            </div>
        </div>
        <!-- End Accordion Area -->

        <Separator/>

        <!-- Start Accordion Area -->
        <div class="rn-accordion-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 offset-lg-1">
                        <SectionTitle
                            class="mb--30"
                            text-align="center"
                            subtitle="Accordion"
                            title="Customize All"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <div class="row mt--35 row--20">
                    <div class="col-lg-10 offset-lg-1">
                        <Accordion
                            id="accordionExampleThree"
                            accordion-style-class="rn-accordion-03"
                            :accordion-content="[
                                {
                                    id: 'Nine',
                                    title: 'What is Doob ? How does it work?',
                                    description: 'You can run doob easily. First You\'ll need to have node and npm on your machine. So Please open your command prompt then check your node -v and npm -v Version. Goes To Your your command prompt: then First: npm install At Last: npm run start. By the following way you can be run your project easily.'
                                },
                                {
                                    id: 'Ten',
                                    title: 'How can I get the customer support?',
                                    description: 'After purchasing the product need you any support you can be share with us with sending mail to rainbowit10@gmail.com.'
                                },
                                {
                                    id: 'Eleven',
                                    title: 'Can I get update regularly and For how long do I get updates?',
                                    description: 'Yes, We will get update the Trydo. And you can get it any time. Next time we will comes with more feature. You can be get update for unlimited times. Our dedicated team works for update.'
                                },
                                {
                                    id: 'Twelve',
                                    title: 'How can I run doob html template?',
                                    description: 'You can run doob easily. First You\'ll need to have node and npm on your machine. So Please open your command prompt then check your node -v and npm -v Version. Goes To Your your command prompt: then First: npm install At Last: npm run start. By the following way you can be run your project easily.'
                                },
                            ]"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
            </div>
        </div>
        <!-- End Accordion Area -->

        <Separator/>

        <!-- Start Accordion Area -->
        <div class="rn-accordion-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 offset-lg-1">
                        <SectionTitle
                            class="mb--30"
                            text-align="center"
                            subtitle="Accordion"
                            title="Customize All"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <div class="row mt--35 row--20">
                    <div class="col-lg-10 offset-lg-1">
                        <Accordion
                            id="accordionExampleFour"
                            accordion-style-class="rn-accordion-04"
                            :accordion-content="[
                                {
                                    id: 'Thirteen',
                                    title: 'What is Doob ? How does it work?',
                                    description: 'You can run doob easily. First You\'ll need to have node and npm on your machine. So Please open your command prompt then check your node -v and npm -v Version. Goes To Your your command prompt: then First: npm install At Last: npm run start. By the following way you can be run your project easily.'
                                },
                                {
                                    id: 'Fourteen',
                                    title: 'How can I get the customer support?',
                                    description: 'After purchasing the product need you any support you can be share with us with sending mail to rainbowit10@gmail.com.'
                                },
                                {
                                    id: 'Fifteen',
                                    title: 'Can I get update regularly and For how long do I get updates?',
                                    description: 'Yes, We will get update the Trydo. And you can get it any time. Next time we will comes with more feature. You can be get update for unlimited times. Our dedicated team works for update.'
                                },
                                {
                                    id: 'Sixteen',
                                    title: 'How can I run doob html template?',
                                    description: 'You can run doob easily. First You\'ll need to have node and npm on your machine. So Please open your command prompt then check your node -v and npm -v Version. Goes To Your your command prompt: then First: npm install At Last: npm run start. By the following way you can be run your project easily.'
                                },
                            ]"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
            </div>
        </div>
        <!-- End Accordion Area -->

        <Separator/>
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import Separator from '../components/elements/separator/Separator'
    import Accordion from '../components/elements/accordion/Accordion'

    export default {
        name: 'AccordionPage',
        components: {Accordion, Layout, Breadcrumb, SectionTitle, Separator}
    }
</script>