<template>
    <Layout>
        <div class="rn-blog-details-area">
            <div class="post-page-banner rn-section-gapTop">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 offset-lg-2">
                            <div class="content text-center">
                                <div class="page-title">
                                    <h3  v-html="post.headline"/>
                                    <p style="color: #666;">{{post.subtitle}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="blog-details-content pt--60 rn-section-gapBottom" style="min-height: 800px">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 offset-lg-2">
                            <div class="ql-editor">
                                <p style="color: #666" v-html="post.content"/>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
    import Icon from '../components/icon/Icon'
    import BlogPostMixin from '../mixins/BlogPostMixin'
    import axios from 'axios'

    export default {
        name: 'BlogDetailsPage',
        components: {Icon, Layout, Breadcrumb},
        mixins: [BlogPostMixin],
        data() {
            return {
                id: this.$route.params.id,
                post: {}
            }
        },
        methods: {
            getPost() {
                axios({
                    url: 'https://slb.yesokeji.com/manager/officialInformation/detail',
                    method: 'get',
                    params: {
                        id: this.id
                    }
                }).then(res => {
                    if (res.data.code == 200) {
                        this.post = res.data.data
                    }
                    
                })
            }
        },
        mounted() {
            this.getPost();
        }
    }
</script>