<template>
    <div class="map-wrap">
      <div class="map-box">
      <div id="amap" class="amap"></div>
    </div> 
    </div>
</template>
 
<script>
import AMap from 'AMap'
export default {
  props: {
    lnglat:{
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return { 
      visible: true,
      address: '上海市闵行区申昆路1999号2号楼8楼',
      point: this.lnglat ,
      amap: null
    }
  },
  mounted () {
    // 地图实例对象 （amap 为容器的id）
    let amap = new AMap.Map('amap', {
      resizeEnable: true,
      zoom: 15
    })
    this.amap = amap
    // 注入插件（定位插件，地理编码插件）
    amap.plugin(['AMap.Geolocation', 'AMap.Geocoder'], () => {
      this.init(this.point, amap)
    })
    
  },
  methods: {

    // 初始化
    init (lnglat, amap) {
      // 定位
      this.currentPosition(amap, lnglat)
    },
    currentPosition (map, lnglat) {
      if (lnglat && lnglat.length) {
        // 有传入坐标点，直接定位到坐标点
        map.setCenter(lnglat)
        this.addMark(map, lnglat)

        // 获取地址
        this.getAddress(lnglat)
      } else {
        // 没有传入坐标点，则定位到当前所在位置
        // let geolocation = new AMap.Geolocation({
        //   enableHighAccuracy: true,
        //   timeout: 10000,
        //   zoomToAccuracy: true,     
        //   buttonPosition: 'RB'
        // })
        // geolocation.getCurrentPosition((status, result) => {
        //   if (status === 'complete') {
        //     let points = [result.position.lng, result.position.lat]
        //     map.setCenter(points) // 设置中心点
        //     this.addMark(map, points) // 添加标记

        //     // 存下坐标与地址
        //     this.point = points
        //     this.getAddress(points)
        //   } else {
        //     console.log('定位失败', result)
        //   }
        // })
        this.getPointByAddress()
      }
    },

    // 添加标记
    addMark (map, points) {
      let marker = new AMap.Marker({
        map: map,
        position: points,
        draggable: false, // 允许拖动
        cursor: 'move',
        raiseOnDrag: true,
        content:  '<div style="color: #1890ff; width: 100px;"><img src="http://webapi.amap.com/theme/v1.3/markers/n/mark_b.png" />'+'<div style="font-weight: bold">上海华夜信息</div>'+ "</div>",
      })
      marker.on('dragend', () => {
        // console.log(e)
        let position = marker.getPosition()

        // 存下坐标与地址
        this.point = [position.lng, position.lat]
        this.getAddress([position.lng, position.lat])
      })
      this.marker = marker
    },

    // 根据坐标返回地址(逆地理编码)
    getAddress (points) {
      let geocoder = new AMap.Geocoder({ radius: 1000 })
      geocoder.getAddress(points, (status, result) => {
        if (status === 'complete' && result.regeocode) {
          this.address = result.regeocode.formattedAddress
        }
      })
    },
    getPointByAddress() {
      if (this.address) {
        if (this.marker) this.amap.remove([this.marker]);
        var geocoder = new AMap.Geocoder();
        geocoder.getLocation(this.address, (status, result) => {
          if (status === 'complete' && result.info === 'OK') {
            // 经纬度
            var lon = result.geocodes[0].location.lng
            var lat = result.geocodes[0].location.lat
            this.point = [lon, lat]
            this.amap.setCenter(this.point)
            this.addMark(this.amap, this.points) // 添加标记
            
          } else {
            console.log('定位失败！')
          }
        })
      } else {
        this.$message.warning('请输入详细地址',1)
      }
      
    },

    commit () {
      this.$emit('location', this.point, this.address)
    },
    cancel() {
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="scss" scoped>
.map-wrap{
//   position: fixed;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   z-index: 1001;
//   height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.map-box {
  box-sizing: border-box;
  background-color: #fff;
  width: 100%;
  height: 450px;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate3d(-50%,-50%,0);
  &:after {
    content: '';
    display: block;
    clear: both;
  }
  .amap, .detail {
    float: left;
    height: 100%;
  }
  .amap {
    width: 100%;    
  }
  .detail {
    width: 30%;
    background-color: #fff;
    padding: 0 15px;    
    border-left: 1px solid #eee;
    box-sizing: border-box;
    word-wrap: break-word;
  }
  .btnmap {
    width: 100%;
    margin: 30px 0 0 0;
    padding: 5px 0;
    color: #fff;
    cursor: pointer;
    background-color: #409eff;
    border: none;    
    border-radius: 3px;
    &:hover {
      background-color: #66b1ff;
    }
  }
}
</style>