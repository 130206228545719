<template>
    <div class="rn-card box-card-style-default"
         :class="{'card-list-view': blogPostStyle === 'list'}">
        <div class="inner">
            <div class="thumbnail">
                <router-link class="image" :to="`/newsdetail/${blog.id}`">
                    <img class="w-100" height=200 :src="blog.imageUrl" :alt="blog.headline">
                </router-link>
            </div>
            <div class="content" style="height: 173px;">
                <h4 class="title" 
                style=" overflow:hidden;
                    text-overflow:ellipsis;
                    display:-webkit-box;
                    -webkit-box-orient:vertical;
                    -webkit-line-clamp:2;">
                    <router-link :to="`/newsdetail/${blog.id}`">
                        {{ blog.headline }}
                    </router-link>
                </h4>
                <ul v-if="blogPostStyle === 'card'" class="rn-meta-list">
                    <li style=" overflow:hidden;
                    text-overflow:ellipsis;
                    display:-webkit-box;
                    -webkit-box-orient:vertical;
                    -webkit-line-clamp:2;">
                        <router-link :to="`/newsdetail/${blog.id}`">
                            {{ blog.subtitle }}
                        </router-link>
                    </li>
                </ul>
                
                <!-- <ul v-if="blogPostStyle === 'list'" class="rn-meta-list">
                    <li>
                        <router-link :to="''">
                            {{ blog.author }}
                        </router-link>
                    </li>
                    <li class="separator">/</li>
                    <li>{{ blog.publishedAt }}</li>
                </ul>
                <p v-if="blogPostStyle === 'list'" class="descriptiion">
                    {{ blog.excerpt }}
                </p> -->
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'BlogPost',
        props: {
            blog: {},
            blogPostStyle: {
                type: String,
                default: 'card'
            }
        },
        data() {
            return {
                newsList: []
            }
        },
        
    }
</script>