<template>
    <Layout header-class="header-left-align" nav-alignment="right">

        <!-- <div id="home">
            <v-carousel
            :slideData="slideData"
            :height="500"
            :begin="0"
            :interval="3000"
            :dot="true"
            :arrow="true"
            ></v-carousel>
        </div> -->
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 bg-transparent " data-aos="zoom-out-up"
                            data-aos-delay="70">
            <img class="w-100" src="../../assets/images/yeso/home/bg.png" alt="About Images">
        </div>
        <!-- End Slider Area -->

        <!-- <Separator/> -->
        <div class="slider-area slider-style-1 bg-transparent">
            <router-link to='/productDetail'>
               <img class="w-100" src="../../assets/images/yeso/device.png" alt="About Images">
            </router-link>
        </div>
        <div class="slider-area slider-style-1 bg-transparent ">
            <router-link to='/productDetail'>
              <img class="w-100" src="../../assets/images/yeso/device2.png" alt="About Images">
            </router-link>
        </div>
        <!-- Start About Area -->
        <!-- <div class="rwt-about-area rn-section-gap">
            <div class="container">
                <div class="row row--30 align-items-center">
                    <div class="col-lg-5">
                        <div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
                            <img class="w-100" src="../../assets/images/yeso/home/about.png" alt="About Images">
                        </div>
                    </div>
                    <div class="col-lg-7 mt_md--40 mt_sm--40">
                        <div class="content">
                            <div class="section-title">
                                <h3 class="title" data-aos="fade-up" data-aos-delay="280">
                                    关于我们
                                </h3>
                                <p style="color: #999;" data-aos="fade-up" data-aos-delay="310">
                                    上海华夜信息科技有限公司，专注于智能化无人零售技术及运营解决方案服务，
                                    公司通过对三轴自动寻址技术及智能售货机终端技术的优化升级，以及结合零售终
                                    端个性化场景需求，独立研发并运营 “夜售”24H智能无人值守货架，为门店24H
                                    营业提供降本增效解决方案，公司领导团队在连锁便利体系、快消品牌公司及无人
                                    零售领域有十余年管理及运营经验，具有强大的软硬件自主研发能力及互联网线上
                                    线下整合能力，拥有20多项技术专利。
                                </p>
                                <div class="goodsData">
                                  <div class="item" v-for="(item,index) in goodsData" :key="index">
                                    <div class="icon"><div class="inner"></div></div>
                                    <div>{{item}}</div>
                                  </div> 
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- End About Area -->

        <!-- Start Call To Action Area -->
        <div class="rn-service-area rn-section-gap">
            <div class="container">
                <h3>为什么选择夜售</h3>
                <p class="color-gray b1">我们致力于培养团结高效的团队</p>
                <div class="row row--15 service-wrapper">
                    <div class="col-lg-4 col-md-8 col-sm-8 col-12">
                        <div :class="`service ${serviceStyle} ${checkTextAlign1}`"
                            data-aos="fade-up"
                            style="padding-left: 50px;"
                            data-aos-delay="70">
                            <div class="icon" style="text-align:left !important;">
                               <img src="../../assets/images/yeso/home/ico1.png" height="100" alt="">
                            </div>
                            <div class="content">
                                <h5 class="title w-600" style="text-align:left !important;">客户服务</h5>
                                <div style="text-align: left;padding-right: 65px;">
                                    <p class="description b1 color-gray mb--0" style="display: flex;line-height: 25px;">
                                        <span>定制设计：</span>
                                        <span style="flex: 1;margin-bottom: 9px">可定制设计机器面板样式</span>
                                    </p>
                                    <p class="description b1 color-gray mb--0" 
                                    style="display: flex;line-height: 25px;" >
                                        <span>上门安装：</span>
                                        <span style="flex: 1;margin-bottom: 9px">提供免费上门测量及安装服务</span>
                                    </p>
                                    <p class="description b1 color-gray mb--0" 
                                    style="display: flex;line-height: 25px;">
                                        <span>24H客服：</span>
                                        <span style="flex: 1;margin-bottom: 9px">全天候24H在线客服</span>
                                    </p>
                                    <p class="description b1 color-gray mb--0" 
                                    style="display: flex;line-height: 25px;">
                                        <span>新店扶持：</span>
                                        <span style="flex: 1">新店提供1V1扶持政策</span>
                                    </p>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-8 col-sm-8 col-12">
                        <div :class="`service ${serviceStyle} ${checkTextAlign1}`"
                            data-aos="fade-up"
                            style="padding-left: 50px;"
                            data-aos-delay="70">
                            <div class="icon" style="text-align:left !important;">
                               <img src="../../assets/images/yeso/home/ico2.png" height="100" alt=""> 
                            </div>
                            <div class="content">
                                <h5 class="title w-600" style="text-align:left !important;">技术服务</h5>
                                <div style="text-align: left;padding-right: 65px;">
                                    <p class="description b1 color-gray mb--0" style="display: flex;line-height: 25px;">
                                        <span>科学补货：</span>
                                        <span style="flex: 1;margin-bottom: 9px">微信小程序智能补货</span>
                                    </p>
                                    <p class="description b1 color-gray mb--0" 
                                    style="display: flex;line-height: 25px;" >
                                        <span>数据分析：</span>
                                        <span style="flex: 1;margin-bottom: 9px">数据分析，品牌用户画像精准</span>
                                    </p>
                                    <p class="description b1 color-gray mb--0" 
                                    style="display: flex;line-height: 25px;">
                                        <span>实时分账：</span>
                                        <span style="flex: 1;margin-bottom: 9px">无账期，收益实时到账</span>
                                    </p>
                                    <p class="description b1 color-gray mb--0" 
                                    style="display: flex;line-height: 25px;">
                                        <span>人脸识别：</span>
                                        <span style="flex: 1">智能人脸识别，支持刷脸支付</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-8 col-sm-8 col-12">
                        <div :class="`service ${serviceStyle} ${checkTextAlign1}`"
                            data-aos="fade-up"
                            style="padding-left: 50px;"
                            data-aos-delay="70">
                            <div class="icon" style="text-align:left !important;">
                               <img src="../../assets/images/yeso/home/ico3.png" height="100" alt=""> 
                            </div>
                            <div class="content">
                                <h5 class="title w-600" style="text-align:left !important;">运营服务</h5>
                                <div style="text-align: left;padding-right: 30px;">
                                    <p class="description b1 color-gray mb--0" style="display: flex;line-height: 25px;">
                                        <span>营销方案：</span>
                                        <span style="flex: 1">协助门店各种营销活动，定制营销方案，并形成数字化反馈</span>
                                    </p>
                                    <p class="description b1 color-gray mb--0" 
                                    style="display: flex;line-height: 25px;" >
                                        <span>用户管理：</span>
                                        <span style="flex: 1">协助门店打造私域流量，运营会员体系</span>
                                    </p>
                                    <p class="description b1 color-gray mb--0" 
                                    style="display: flex;line-height: 25px;">
                                        <span>商业路由：</span>
                                        <span style="flex: 1">连接品牌及广告、外卖等异业合作，人、货、场精准匹配</span>
                                    </p>
                                    <p class="description b1 color-gray mb--0" 
                                    style="display: flex;line-height: 25px;">
                                        <span>媒体矩阵：</span>
                                        <span style="flex: 1">新媒体全渠道运营矩阵，曝光无忧</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                        <div :class="`service ${serviceStyle} ${checkTextAlign1}`"
                            data-aos="fade-up"
                            data-aos-delay="70">
                            <div class="icon">
                               <img src="../../assets/images/yeso/home/ico4.png" height="100" alt=""> 
                            </div>
                            <div class="content">
                                <h5 class="title w-600">降低运营成本</h5>
                                <p class="description b1 color-gray mb--0">为门店解决营业时长、<br />租金过高、库存积压、<br />人员成本过高等一系列问题</p>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- End Call To Action Area -->


        <Separator/>

        <!-- Start Service Area -->
        <div class="rn-service-area rn-section-gap">
            <div class="container">
                <h3>我们能为您做什么</h3>
                <p class="color-gray b1">我们竭尽所能，只为给您提供更全面、更优质的服务</p>
                <div class="row row--15 service-wrapper">
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                        <div :class="`service ${serviceStyle} ${checkTextAlign}`"
                            data-aos="fade-up"
                            data-aos-delay="70">
                            <div class="icon">
                               <img src="../../assets/images/yeso/home/icon0.png" class="img0" width="55" alt=""> 
                               <img src="../../assets/images/yeso/home/icon00.png" class="img1" width="55" alt="">
                            </div>
                            <div class="content">
                                <h5 class="title w-600">夜售服务系统</h5>
                                <p class="description b1 color-gray mb--0">夜售后台系统同时兼容智能货架、智能售货机、智能自取柜统一管理</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                        <div :class="`service ${serviceStyle} ${checkTextAlign}`"
                            data-aos="fade-up"
                            data-aos-delay="70">
                            <div class="icon">
                               <img src="../../assets/images/yeso/home/icon1.png" class="img0" width="55" alt=""> 
                               <img src="../../assets/images/yeso/home/icon10.png" class="img1" width="55" alt="">
                            </div>
                            <div class="content">
                                <h5 class="title w-600">小程序管理</h5>
                                <p class="description b1 color-gray mb--0">可实时查看设备状况、实时销售数据、商品库存及缺货状况；对设备运营补货、调整货道、等各项管理设置</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                        <div :class="`service ${serviceStyle} ${checkTextAlign}`"
                            data-aos="fade-up"
                            data-aos-delay="70">
                            <div class="icon">
                               <img src="../../assets/images/yeso/home/icon2.png" class="img0" width="55" alt=""> 
                               <img src="../../assets/images/yeso/home/icon20.png" class="img1" width="55" alt="">
                            </div>
                            <div class="content">
                                <h5 class="title w-600">设备管理信息</h5>
                                <p class="description b1 color-gray mb--0">设备运维管理、订单管理与报表系统、电子支付系统、数据分析系统、财务分析与提成、营销促销与广告管理系统</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                        <div :class="`service ${serviceStyle} ${checkTextAlign}`"
                            data-aos="fade-up"
                            data-aos-delay="70">
                            <div class="icon">
                               <img src="../../assets/images/yeso/home/icon3.png" class="img0" width="55" alt=""> 
                               <img src="../../assets/images/yeso/home/icon30.png" class="img1" width="55" alt="">
                            </div>
                            <div class="content">
                                <h5 class="title w-600">收款</h5>
                                <p class="description b1 color-gray mb--0">通过微信、支付宝、云闪付、数字人民币及刷脸机支付收款、分账，实时到账</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                        <div :class="`service ${serviceStyle} ${checkTextAlign}`"
                            data-aos="fade-up"
                            data-aos-delay="70">
                            <div class="icon">
                               <img src="../../assets/images/yeso/home/icon4.png" class="img0" width="55" alt=""> 
                               <img src="../../assets/images/yeso/home/icon40.png" class="img1" width="55" alt="">
                            </div>
                            <div class="content">
                                <h5 class="title w-600">购物车功能</h5>
                                <p class="description b1 color-gray mb--0">可实现多品种多数量选购，一次付款</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                        <div :class="`service ${serviceStyle} ${checkTextAlign}`"
                            data-aos="fade-up"
                            data-aos-delay="70">
                            <div class="icon">
                               <img src="../../assets/images/yeso/home/icon5.png" class="img0" width="55" alt=""> 
                               <img src="../../assets/images/yeso/home/icon50.png" class="img1" width="55" alt="">
                            </div>
                            <div class="content">
                                <h5 class="title w-600">优惠活动设置</h5>
                                <p class="description b1 color-gray mb--0">系统支持多种优惠活动设置，便于进行单客户、系统客户、单品等营销推广</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                        <div :class="`service ${serviceStyle} ${checkTextAlign}`"
                            data-aos="fade-up"
                            data-aos-delay="70">
                            <div class="icon">
                               <img src="../../assets/images/yeso/home/icon6.png" class="img0" width="55" alt=""> 
                               <img src="../../assets/images/yeso/home/icon60.png" class="img1" width="55" alt="">
                            </div>
                            <div class="content">
                                <h5 class="title w-600">会员系统</h5>
                                <p class="description b1 color-gray mb--0">支持一机一码，精准定位会员，定向投送营销活动及宣传，引流会员消费</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                        <div :class="`service ${serviceStyle} ${checkTextAlign}`"
                            data-aos="fade-up"
                            data-aos-delay="70">
                            <div class="icon">
                               <img src="../../assets/images/yeso/home/icon7.png" class="img0" width="55" alt=""> 
                               <img src="../../assets/images/yeso/home/icon70.png" class="img1" width="55" alt="">
                            </div>
                            <div class="content">
                                <h5 class="title w-600">设备状态</h5>
                                <p class="description b1 color-gray mb--0">设备故障实时报警，推送消息到指定账户，时刻掌握设备状态</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Service Area -->
        <Separator/>

        <div class="rn-blog-area rn-section-gap">
            <div class="container">
                <h3 class="title" data-aos="fade-up" style="text-align: center;" data-aos-delay="280">新闻资讯</h3>
                <div class="row mt_dec--30">
                    <div class="col-lg-12">
                        <div class="row row--15">
                            <div class="col-lg-3 col-md-6 col-12 mt--30"
                                 data-aos="slide-up"
                                 data-aos-duration="800"
                                 :data-aos-delay="100 + index"
                                 v-for="(blog, index) in posts"
                                 :key="index">
                                <BlogPost :blog="blog"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
       <Separator/>
        
        <!-- Start Team Area --> 
        <div class="container" style="padding: 80px 0;">
            <div class="slider-area slider-style-1 bg-transparent ">
                <router-link to="/contact"><img class="w-100" src="../../assets/images/yeso/entry.png" alt="About Images"></router-link>
            </div>
        </div>
        <!-- End Team Area -->


       
        <!-- End Blog Area -->
    </Layout>
</template>

<script>
    import Layout from '../../components/common/Layout'
    import Separator from '../../components/elements/separator/Separator'
    import Icon from '../../components/icon/Icon'
    import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
    import ServiceFive from '../../components/elements/service/ServiceFive'
    import Team from '../../components/elements/team/Team'
    import BlogPost from '../../components/blog/BlogPost'
    import BlogPostMixin from '../../mixins/BlogPostMixin'
    import CallToAction from '../../components/elements/callToAction/CallToAction'
    import Testimonial from '../../components/elements/testimonial/Testimonial'
    import Accordion from '../../components/elements/accordion/Accordion'
 

    export default {
        name: 'BusinessConsulting2',
        components: {
            Accordion,
            Testimonial,
            CallToAction,
            BlogPost,
            Team,
            ServiceFive,
            SectionTitle,
            Icon,
            Separator,
            Layout,
        },
        mixins: [BlogPostMixin],
        data() {
            return {
                textAlign: 'left',
                textAlign1: 'center',
                iconSize: "40",
                serviceStyle: 'service-style="service__style--1 bg-color-blackest mt--25',
                goodsData: ['24小时','夜间销售','一站式整合营销服务','人脸识别','人工智能','公司间合作无后顾之忧'],
                slideData: [
                    {
                    title: "这是一个Vue轮播图组件的第一张视频",
                    url: "#",
                    videos: [
                        {
                        video:
                            "http://dev-yeso.oss-cn-shanghai.aliyuncs.com/yeso-video-official/video1.mp4",
                        }],
                    },
                    {
                    title: "这是一个Vue轮播图组件的第二张视频",
                    url: "#",
                    videos: [
                        {
                        video:
                            "http://dev-yeso.oss-cn-shanghai.aliyuncs.com/yeso-video-official/video2.mp4",
                        }],
                    },
                ]
            }
        },
        computed: {
            checkTextAlign() {
                if (this.textAlign === 'left') return 'text-start';
                else if (this.textAlign === 'center') return 'text-center';
                else if (this.textAlign === 'end') return 'text-end';
                else return '';
            },
            checkTextAlign1() {
                if (this.textAlign1 === 'left') return 'text-start';
                else if (this.textAlign1 === 'center') return 'text-center';
                else if (this.textAlign1 === 'end') return 'text-end';
                else return '';
            }
        },
       
        
    }
</script>