<template>
    <Layout  :show-newsletter="true" :show-cta="false">
      <div class="slider-area slider-style-1 bg-transparent  " data-aos="zoom-in-up"
                            data-aos-delay="70" style="width 100%;height: 0;padding-bottom: 36.3%">
            <img class="w-100" src="../assets/images/yeso/comeon.png" alt="About Images">
        </div>
       <div class="rwt-contact-area rn-section-gap">
            <div class="container" style="padding: 0 100px">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="contact-title">前景超好</div>
                        <p class="color-gray b1"> 随着人工智能、大数据、云计算、物联网等新兴技术的爆发式发展以及消费模式的升级，零售行业整上演着一场以“智慧零售”为
                         主要内容的变革。在互联网、大数据等科技加速发展的大背景下，人脸识别、商品识别、扫码支付等新技术不断应用于智慧零售、无
                         人便利店、自动售货机等。智慧零售成为全球零售业的趋势，中国巨大的消费市场体量将为智慧零售的发展提供强劲动力。</p>
                    </div>
                    <div class="col-lg-12" style="padding: 70px 0">
                        <div class="contact-title">钱景超好</div>
                        <p class="color-gray b1"> 我们已完成X轮X元融资，疫情期间，是为数不多仍然实现稳步增长的企业，一年累计服务1000+以上门店……关于未来，关于钱景值得我们共同期待。</p>
                    </div>
                    <div class="col-lg-12">
                        <div class="contact-title">同事超牛</div>
                        <p class="color-gray b1"> 高考状元、清华、北大、复旦……名校的各种超级学霸各类国际竞赛获奖、奖学金拿到手软的“天才”……我们已经与他们并肩作战许久，期待你的加入为夜售大家庭注入新鲜血液。</p>
                    </div>
                    <div class="col-lg-12" style="padding: 70px 0">
                        <div class="contact-title">荣誉奖项</div>
                        <p class="color-gray b1"> 成立以来，荣誉不断，除了国家双高新认证之外，我们还是上海市“专精特新”中小企业、XX强企业。</p>
                    </div>
                    <div class="col-lg-12">
                        <div class="contact-title">团队文化</div>
                        <p class="color-gray b1"> 我们是“Work hard, play hard”的践行者。团队扁平化管理，解决问题为唯一导向。加入夜售大家庭，还能获得很多工作成就感之外的欢乐，生日趴体、团建活动等等，每个月都有新花样。</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Elements Area -->
        <div style="background: #F8F7FC;">
            <div class="container" style="padding: 30px 100px">
                <h3 style="font-size: 30px;">社会招聘</h3>
                <div class="row" style="background: #fff;padding: 40px;margin-bottom: 20px;">
                    <div class="col-lg-12">
                        <div style="font-size: 30px;color: #3574C1;">Android开发工程师</div>
                        <div style="font-size: 30px;color: #333;margin-top: 20px;">任职要求</div>
                        <p class="color-gray b1" style="line-height: 28px;">
                            1、本科以上学历，3年以上Android平台开发经验；精通java程序设计、语言特性、内存管理机制以及JVM基本原理；<br />
                            2、精通Android开发技术，熟悉常用Android SDK/框架，包括UI、事件处理、网络、多线程模型等方面；<br />
                            3、有良好的编码风格，追求代码执行效率，了解常用数据结构和算法；<br />
                            4、有责任感，工作态度严谨，能够承担高强度、高压力的工作；<br />
                            5、具备较强的逻辑思维能力和沟通表达能力。
                        </p>
                    </div>
                </div>
                <div class="row" style="background: #fff;padding: 40px;margin-bottom: 20px;">
                    <div class="col-lg-12">
                        <div style="font-size: 30px;color: #3574C1;">Web前端开发工程师</div>
                        <div style="font-size: 30px;color: #333;margin-top: 20px;">任职要求</div>
                        <p class="color-gray b1" style="line-height: 28px;">
                            1、大专以上，3年以上相关经验；<br />
                            2、熟悉PC端及移动端的前端开发、多浏览器兼容及移动端机型适配方式；<br />
                            3、熟练掌握web系统开发技术，精通javascript、jquery、CSS3、HTML5、Ajax，能快速定位 解决浏览器兼容性问题；<br />
                            4、熟练使用JS及主流的JavaScript框架，如Dojo、Bootstrap、EasyUI等前端框架；<br />
                            5、熟悉常见页面布局方式，移动端响应式页面布局方式，熟悉css性能优化方式；<br />
                            6、熟悉W3C标准，对表现与数据分离、Web语义化等有深刻理解；<br />
                            7、熟练使用Oracle或SQLSERVER；<br />
                            8、熟悉至少一种版本控制工具，如：SVN、Git、Mercurial；<br />
                            9、了解至少一门前端MVVM框架，如angular，vue,react等。<br />
                            10、喜欢并且善于跟团队成员交流沟通, 对于新知识技术有强力的学习愿望和极强的学习能力。
                        </p>
                    </div>
                </div>
                <div class="row" style="background: #fff;padding: 40px;margin-bottom: 20px;">
                    <div class="col-lg-12">
                        <div style="font-size: 30px;color: #3574C1;">Java开发工程师</div>
                        <div style="font-size: 30px;color: #333;margin-top: 20px;">任职要求</div>
                        <p class="color-gray b1" style="line-height: 28px;">
                            1、本科及以上计算机、统计学、数据库等相关专业，3年以上数据相关工作；<br />
                            2、熟悉当前主流的开源数据平台和工具以及数据云服务，如：Hadoop、HBase、Spark等，并具有Hands-On能力；<br />
                            3、对业务数据模型、数据仓库、数据分等大数据系统的架构有清晰的思路和落地执行的方案，对数据服务、数据产品的模式有清晰
                            的理解；<br />
                            4、熟悉Java语言体系相关技术和网站、网络应用开发，熟悉JVM基本原理；<br />
                            5、熟悉MVC开发架构，对Spring、MyBatis等开源框架应用经验丰富；<br />
                            6、对Linux开发和运行环境非常熟悉；熟练使用Tomcat、Jetty等Web服务器，熟悉Dubbo、Thrift等RPC框架；<br />
                            7、熟悉HTML、CSS、XML、JSON等技术，熟练掌握JS/JQuery, AJAX等前端技术者优先，欢迎Javaweb全栈工程师；<br />
                            8、对数据库原理、数据存储原理、常用数据分析算法深刻的理解，并善于分析业务数据并将算法和数据平台技术应用与实际业务产品。
                        </p>
                    </div>
                </div>
                <div class="row" style="background: #fff;padding: 40px;margin-bottom: 20px;">
                    <div class="col-lg-12">
                        <div style="font-size: 30px;color: #3574C1;">性能测试工程师</div>
                        <div style="font-size: 30px;color: #333;margin-top: 20px;">任职要求</div>
                        <p class="color-gray b1" style="line-height: 28px;">
                            1、计算机或相关专业专科及以上学历；3年以上测试工作经验，有大型项目测试经验，项目用户群千万以上级；具有性能测试实际
                            工作经验；<br />
                            2、 熟练使用至少一种商业性能测试工具和开源性能测试工具，如LoadRunner、JMeter；<br />
                            3、 具备一定的设计编码能力，能熟练进行性能测试脚本编写；<br />
                            4、 熟练掌握数据库（SQL Server或Mysql）各项性能参数设置、监控及优化；<br />
                            5、 熟练掌握Java，熟悉C/S、B/S及其三层结构的开发模式；<br />
                            6、 熟悉常用的应用服务器（java和nginx）的配置调优；<br />
                            7、 具有较强的业务分析能力，沟通表达能力和综合协调能力，工作积极主动。

                        </p>
                    </div>
                </div>
                <div class="row" style="background: #fff;padding: 40px;margin-bottom: 20px;">
                    <div class="col-lg-12">
                        <div style="font-size: 30px;color: #3574C1;">产品经理</div>
                        <div style="font-size: 30px;color: #333;margin-top: 20px;">任职要求</div>
                        <p class="color-gray b1" style="line-height: 28px;">
                            1、本科及以上学历，3年以上互联网、物联网产品工作经验优先；<br />
                            2、具备良好的产品分析与设计思路，较强的逻辑分析能力；<br />
                            3、拥有良好的结构化思考能力、语言表达能力，善于跨部门协调沟通；<br />
                            4、具备项目管理经验，用严谨科学的统筹方法保证工作按时按质按量完成；<br />
                            5、熟练完成PRD产品文档，熟悉UI、交互、布局设计，熟练使用Axure等常用产品设计工具；<br />
                            6、拥有一颗产品心，愿意与创始人一起用心做好产品工作，追求极致，突破创新，具备创业精神和抗压能力。
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import GoogleMap from "../components/elements/contact/GoogleMap";
    import ContactForm from "../components/elements/contact/ContactForm";
    import Icon from "../components/icon/Icon";

    export default {
        name: 'About',
        components: {ContactForm, GoogleMap, Layout,Icon},
        data() {
            return {
                goodsData: ['24小时','夜间销售','一站式整合营销服务','人脸识别','人工智能','公司间合作无后顾之忧'],
                brandList: [
                    {
                        image: require(`@/assets/images/brand/brand-01.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-02.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-03.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-04.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-05.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-06.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-07.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-08.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-01.png`)
                    },

                ],
                timelineData: [
                    {
                        id: '1',
                        title: 'Knowledge',
                        description: 'Present all available features in Essentials.',
                    },
                    {
                        id: '2',
                        title: 'Working',
                        description: 'All Feature available features in Essentials.',
                    },
                    {
                        id: '3',
                        title: 'Solution',
                        description: 'Popular Feature available features in Essentials.',
                    },
                    {
                        id: '4',
                        title: 'Process',
                        description: 'Latest Feature available features in Essentials.',
                    },
                ],
                counterData: [
                    {
                        number: 199,
                        title: 'Happy Clients',
                    },
                    {
                        number: 575,
                        title: 'Employees',
                    },
                    {
                        number: 69,
                        title: 'Useful Programs',
                    },
                    {
                        number: 500,
                        title: 'Useful Programs',
                    }
                ],
                teamData: [
                    {
                        image: 'team-dark-01',
                        name: 'Sr Janen Sara',
                        designation: 'Sr Product Designer',
                        location: 'CO Miego, AD, USA',
                        description: 'Yes, I am a product designer. I have a passion for product design.'
                    },
                    {
                        image: 'team-dark-02',
                        name: 'Corporate Jane',
                        designation: 'Manager',
                        location: 'Bangladesh',
                        description: 'Yes, I am a product designer. I have a passion for product design.'
                    },
                    {
                        image: 'team-dark-03',
                        name: 'Jara Saraif',
                        designation: 'Software Developer',
                        location: 'Poland',
                        description: 'Yes, I am a product designer. I have a passion for product design.'
                    },
                    {
                        image: 'team-dark-04',
                        name: 'Afanan Sifa',
                        designation: 'Accounts Manager',
                        location: 'Poland',
                        description: 'Yes, I am a product designer. I have a passion for product design.'
                    },
                ]
            }
        }
    }
</script>