<template>
    <Layout  header-class="header-left-align" nav-alignment="right">

        <!-- <div class="slider-area slider-style-1 bg-transparent" >
            <img class="w-100" src="../assets/images/yeso/about/info.png" alt="About Images">
        </div>

        <div class="slider-area slider-style-1 bg-transparent" >
            <img class="w-100" src="../assets/images/yeso/about/about.png" alt="About Images">
        </div> -->
           
        <div class="rn-service-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <h3 style="text-align: center;">连锁门店</h3>
                        <div class="slider-area slider-style-1 bg-transparent" style="width 100%;height: 0;padding-bottom: 32.6%">
                            <img class="w-100" src="../assets/images/yeso/store.png" alt="About Images">
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <!-- End Service Area -->
       <Separator/>
       <div class="rn-service-area rn-section-gap">
            <div class="" style="max-width: 800px;min-width: 400px;margin: auto;">
                <div class="row">
                    <div class="col-lg-12">
                        <h3 style="text-align: center;">品牌案例</h3>
                        <div class="slider-area slider-style-1 bg-transparent">
                          <vue-seamless-scroll
                            :data="logoData"
                            :class-option="seamlessScrollOption"
                            style="
                              max-width: 800px;
                              min-width: 400px;
                              height: 300px;
                              overflow: hidden;
                            "
                          >
                          <ul style="width: 1470px;">
                            <li style="float:left;list-style-type:none;"><img  src="../assets/images/yeso/productLogo/logo1.png" height=300 width=162 alt=""></li>
                            <li style="float:left;list-style-type:none;"><img  src="../assets/images/yeso/productLogo/logo2.png" width=207 height=300 alt=""></li>
                            <li style="float:left;list-style-type:none;"><img  src="../assets/images/yeso/productLogo/logo3.png" width=209 height=300 alt=""></li>
                            <li style="float:left;list-style-type:none;"><img  src="../assets/images/yeso/productLogo/logo4.png" width=164 height=300 alt=""></li>
                            <li style="float:left;list-style-type:none;"><img  src="../assets/images/yeso/productLogo/logo5.png" width=228 height=300 alt=""></li>
                            <li style="float:left;list-style-type:none;"><img  src="../assets/images/yeso/productLogo/logo6.png" width=251 height=300 alt=""></li>
                            <li style="float:left;list-style-type:none;"><img  src="../assets/images/yeso/productLogo/logo7.png" width=222 height=300 alt=""></li>
                          </ul>
                            
                            <!-- <img  src="'../assets/images/yeso/productLogo/logo1.png'" width=100 alt=""> -->
                          </vue-seamless-scroll>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>

        

        <!-- End Elements Area -->
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Separator from '../components/elements/separator/Separator'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import AboutFour from '../components/elements/about/AboutFour'
    import ServiceOne from '../components/elements/service/ServiceOne'
    import Timeline from '../components/elements/timeline/Timeline'
    import Counter from '../components/elements/counterUp/Counter'
    import Brand from '../components/elements/brand/Brand'
    import Team from '../components/elements/team/Team'
    import VueSeamlessScroll from 'vue-seamless-scroll'

    export default {
        name: 'About',
        components: {Team, Brand, Counter, Timeline, ServiceOne, AboutFour, SectionTitle, Separator, Layout, VueSeamlessScroll},
        data() {
            return {
                goodsData: ['24小时','夜间销售','一站式整合营销服务','人脸识别','人工智能','公司间合作无后顾之忧'],
                logoData: [1,2,3,4,5,6,7],
                serviceStyle: 'service-style="service__style--1  mt--25',
                textAlign1: 'center',
                timelineData: [
                    {
                        id: '1',
                        title: 'Knowledge',
                        description: 'Present all available features in Essentials.',
                    },
                    {
                        id: '2',
                        title: 'Working',
                        description: 'All Feature available features in Essentials.',
                    },
                    {
                        id: '3',
                        title: 'Solution',
                        description: 'Popular Feature available features in Essentials.',
                    },
                    {
                        id: '4',
                        title: 'Process',
                        description: 'Latest Feature available features in Essentials.',
                    },
                ],
                counterData: [
                    {
                        number: 199,
                        title: 'Happy Clients',
                    },
                    {
                        number: 575,
                        title: 'Employees',
                    },
                    {
                        number: 69,
                        title: 'Useful Programs',
                    },
                    {
                        number: 500,
                        title: 'Useful Programs',
                    }
                ],
                teamData: [
                    {
                        image: 'team-dark-01',
                        name: 'Sr Janen Sara',
                        designation: 'Sr Product Designer',
                        location: 'CO Miego, AD, USA',
                        description: 'Yes, I am a product designer. I have a passion for product design.'
                    },
                    {
                        image: 'team-dark-02',
                        name: 'Corporate Jane',
                        designation: 'Manager',
                        location: 'Bangladesh',
                        description: 'Yes, I am a product designer. I have a passion for product design.'
                    },
                    {
                        image: 'team-dark-03',
                        name: 'Jara Saraif',
                        designation: 'Software Developer',
                        location: 'Poland',
                        description: 'Yes, I am a product designer. I have a passion for product design.'
                    },
                    {
                        image: 'team-dark-04',
                        name: 'Afanan Sifa',
                        designation: 'Accounts Manager',
                        location: 'Poland',
                        description: 'Yes, I am a product designer. I have a passion for product design.'
                    },
                ]
            }
        },
        computed: {
            checkTextAlign1() {
                if (this.textAlign1 === 'left') return 'text-start';
                else if (this.textAlign1 === 'center') return 'text-center';
                else if (this.textAlign1 === 'end') return 'text-end';
                else return '';
            },
            seamlessScrollOption() {
              return {
                step: 0.8, // 数值越大速度滚动越快
                limitMoveNum: this.logoData.length, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 2, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
              };
            },
        }
    }
</script>