<template>
    <Layout  header-class="header-left-align" nav-alignment="right">
        <!-- Start Slider Area -->
        <!-- <div class="slider-area slider-style-1 height-850 bg_image"
             data-black-overlay="7"
             :style="{'background-image': `url(${require(`@/assets/images/yeso/about/bg.jpg`)})`}">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner pt--80 text-center">
                            <div>
                                <h3 class="rn-sub-badge" data-aos="fade-up" data-aos-delay="100">
                                    <span class="theme-gradient">Our Company's About Details.</span>
                                </h3>
                            </div>
                            <h1 class="title display-one" data-aos="fade-up" data-aos-delay="150">
                                We are a Corporate <br/> Business Agency.
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        
        <div class="slider-area slider-style-1 bg-transparent" data-aos="zoom-in-up"
                            data-aos-delay="70" style="width 100%;height: 0;padding-bottom: 36.3%">
            <img class="w-100" src="../assets/images/yeso/about/bg.png" alt="About Images">
        </div>
        <!-- End Slider Area -->

        <!-- Start Service Area -->
       <div class="rwt-about-area rn-section-gap">
            <div class="" style="width: 80%;margin: auto;">
                <div class="row row--30 align-items-center">
                    <div class="col-lg-5">
                        <div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
                            <video-player  
                                class="video-player vjs-custom-skin"
                                ref="videoPlayer" 
                                :playsinline="true" 
                                :options="playerOptions"
                            />
                        </div>
                    </div>
                    <div class="col-lg-7 mt_md--40 mt_sm--40">
                        <div class="content">
                            <div class="section-title">
                                <h3 class="title" data-aos="fade-up" data-aos-delay="280">
                                    关于我们
                                </h3>
                                <p style="color: #999;" data-aos="fade-up" data-aos-delay="310">
                                华夜信息是一家零售门店智能化运营平台，专注于为零售门店提供
                                数字化及智能化解决方案和运营服务。通过对三轴自动寻址技术及智能
                                售货机终端技术的优化升级，并结合零售行业SaaS应用及终端个性化
                                场景需求，独立研发并运营“夜售”智能机器人货架，为门店24H营业提
                                供降本增效解决方案。
                                </p>
                                <div class="goodsData">
                                  <div class="item" v-for="(item,index) in goodsData" :key="index">
                                    <div class="icon"><div class="inner"></div></div>
                                    <div>{{item}}</div>
                                  </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Service Area -->
        <!-- <div class="slider-area slider-style-1 bg-transparent" style="width 100%;height: 0;padding-bottom: 29.3%" >
            <img class="w-100" src="../assets/images/yeso/about/info.png" alt="About Images">
        </div> -->

        <div class="slider-area slider-style-1 bg-transparent" style="width 100%;height: 0;padding-bottom: 41.1%">
            <img class="w-100" src="../assets/images/yeso/about/about.png" alt="About Images">
        </div>
        <div class="container" style="padding: 50px 0;">
            <div class="row">
                <div class="col-lg-12 mb--10" style="text-align: center;">
                    <h3 style="color: #333;">联系我们</h3>
                    <p class="color-gray b1">如果您对产品产生兴趣或者疑问，欢迎您留下您的联系方式</p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-sm-24 form-group">
                    <select v-model="form.consultType" style="color: #4562F0;font-size: 16px;" @change="selectClass($event)" >
                        <option style="padding: 5px;" value="" disabled selected>咨询类型</option>
                        <option style="padding: 5px;" v-for="(options,id) in selectClassData" :key="id" :value="options.id">
                            {{options.title}}
                        </option>
                    </select>
                </div>
                <div class="col-lg-6 col-sm-24 form-group">
                    <input
                        type="text"
                        name="fullname"
                        placeholder="您的姓名"
                        v-model="form.consulterName"
                    />
                </div>
                <div class="col-lg-6 col-sm-24 form-group">
                    <input
                        type="text"
                        name="fullname"
                        placeholder="您的电话"
                        v-model="form.consulterPhone"
                    />
                </div>
                <div class="col-lg-6 col-sm-24 form-group">
                    <input
                        type="text"
                        name="fullname"
                        placeholder="您的邮箱"
                        v-model="form.consulterEmail"
                    />
                </div>
                <div class="col-lg-6 col-sm-24 form-group">
                    <input
                        type="text"
                        name="fullname"
                        placeholder="公司名称"
                        v-model="form.consulterCompanyName"
                    />
                </div>
                <div class="col-lg-6 col-sm-24 form-group">
                    <input
                        type="text"
                        name="fullname"
                        v-model="form.consulterCity"
                        placeholder="您的城市"
                    />
                </div>
                <div class="col-lg-12 col-sm-24 form-group">
                    <textarea
                    name="message"
                    v-model="form.message"
                    placeholder="您的留言">
                </textarea>
                </div>
                <div class="form-group" style="width: 60%;margin: auto;">
                    <button class="btn-default" @click="confirmInfo">提交</button>
                </div>
            </div>
        </div>

        

        
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Separator from '../components/elements/separator/Separator'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import AboutFour from '../components/elements/about/AboutFour'
    import ServiceOne from '../components/elements/service/ServiceOne'
    import Timeline from '../components/elements/timeline/Timeline'
    import Counter from '../components/elements/counterUp/Counter'
    import Brand from '../components/elements/brand/Brand'
    import Team from '../components/elements/team/Team'
    import axios from 'axios'

    import 'video.js/dist/video-js.css'
    import 'vue-video-player/src/custom-theme.css'
    
    import { videoPlayer } from 'vue-video-player'

    export default {
        name: 'About',
        components: {videoPlayer,Team, Brand, Counter, Timeline, ServiceOne, AboutFour, SectionTitle, Separator, Layout},
        data() {
            return {
                goodsData: ['24小时','夜间销售','一站式整合营销服务','人脸识别','人工智能','公司间合作无后顾之忧'],
                serviceStyle: 'service-style="service__style--1  mt--25',
                textAlign1: 'center',
                selectClassData:[ //类别选择数据或者从后台获取数据
                    {id:1,title:"合作"},
                    {id:2,title:"加盟"},
                    {id:3,title:"投资"},
                ],

                form: {
                    consultType: '',
                    consulterCity: undefined,
                    consulterCompanyName: undefined,
                    consulterEmail: undefined,
                    consulterName: undefined,
                    consulterPhone: undefined,
                    message: undefined
                },
                playerOptions : {
                    playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
                    autoplay: true, //如果true,浏览器准备好时开始回放。
                    muted: false, // 默认情况下将会消除任何音频。
                    loop: false, // 导致视频一结束就重新开始。
                    preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    language: 'zh-CN',
                    aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    sources: [{
                    type: "video/mp4",//这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
                    src: 'http://dev-yeso.oss-cn-shanghai.aliyuncs.com/yeso-video-official/video2.mp4' //url地址
                    }],
                    poster: "", //你的封面地址
                    // width: document.documentElement.clientWidth, //播放器宽度
                    notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                    controlBar: {
                    timeDivider: true,
                    durationDisplay: true,
                    remainingTimeDisplay: false,
                    fullscreenToggle: true  //全屏按钮
                   }
                }
            }
        },
        computed: {
            checkTextAlign1() {
                if (this.textAlign1 === 'left') return 'text-start';
                else if (this.textAlign1 === 'center') return 'text-center';
                else if (this.textAlign1 === 'end') return 'text-end';
                else return '';
            }
        },
        methods: {
            selectClass(event){
               this.form.consultType = event.target.value; 
            },
            confirmInfo() {
                axios({
                    url: 'https://slb.yesokeji.com/manager/officialInformation/submitContactInformation',
                    method: 'post',
                    data: this.form
                }).then(res => {
                    if (res.data.code == 200) {
                        alert('提交成功')
                        this.form = {
                            consultType: '',
                            consulterCity: undefined,
                            consulterCompanyName: undefined,
                            consulterEmail: undefined,
                            consulterName: undefined,
                            consulterPhone: undefined,
                            message: undefined
                        }
                    }
                    
                })
            },
        },
    }
</script>