import AppFunctions from '../helpers/AppFunctions'
import axios from 'axios'

export default {
    data() {
        return {
            posts: [],
            categories: '',
            tags: '',
            AppFunctions
        }
    },
    mounted () {
        this.getData();
    },
    methods: {
        getData() {
            axios({
                url: 'https://slb.yesokeji.com/manager/officialInformation/getReleasedInformation',
                method: 'get',
            }).then(res => {
                if (res.data.code == 200) {
                    this.posts = res.data.data
                }
                
            })
        }
    }
}