import Vue from 'vue'
import VueRouter from 'vue-router'
import Demo from '../views/Demo'
import About from '../views/About'
import ButtonPage from '../views/ButtonPage'
import ServicePage from '../views/ServicePage'
import ServiceDetailsPage from '../views/ServiceDetailsPage'
import CounterUpPage from '../views/CounterUpPage'
import ProgressbarPage from '../views/ProgressbarPage'
import AccordionPage from '../views/AccordionPage'
import SocialSharePage from '../views/SocialSharePage'
import BlogGridPage from '../views/BlogGridPage'
import BlogGridSidebarPage from '../views/BlogGridSidebarPage'
import BlogListPage from '../views/BlogListPage'
import BlogListSidebarPage from '../views/BlogListSidebarPage'
import BlogDetailsPage from '../views/BlogDetailsPage'
import BlogCategoryPosts from '../components/blog/BlogCategoryPosts'
import TeamPage from '../views/TeamPage'
import PortfolioPage from '../views/PortfolioPage'
import PortfolioThreeColumnPage from '../views/PortfolioThreeColumnPage'
import PortfolioFullWidthPage from '../views/PortfolioFullWidthPage'
import PortfolioGridLayoutPage from '../views/PortfolioGridLayoutPage'
import PortfolioBoxLayoutPage from '../views/PortfolioBoxLayoutPage'
import PortfolioDetailsPage from '../views/PortfolioDetailsPage'
import TestimonialPage from '../views/TestimonialPage'
import TimelinePage from '../views/TimelinePage'
import TabPage from '../views/TabPage'
import PricingPage from '../views/PricingPage'
import SplitSectionPage from '../views/SplitSectionPage'
import CallToActionPage from '../views/CallToActionPage'
import VideoPage from '../views/VideoPage'
import GalleryPage from '../views/GalleryPage'
import ContactPage from '../views/contact'
import BrandPage from '../views/BrandPage'
import ErrorPage from '../views/404'
import AdvanceTabPage from '../views/AdvanceTabPage'
import BrandCarouselPage from '../views/BrandCarouselPage'
import AdvancePricingPage from '../views/AdvancePricingPage'
import BusinessConsulting from '../views/home-pages/BusinessConsulting'
import BusinessConsulting2 from '../views/home-pages/BusinessConsulting2'
import Corporate from '../views/home-pages/Corporate'
import Business from '../views/home-pages/Business'
import DigitalAgency from '../views/home-pages/DigitalAgency'
import Finance from '../views/home-pages/Finance'
import Company from '../views/home-pages/Company'
import MarketAgency from '../views/home-pages/MarketAgency'
import TravelAgency from '../views/home-pages/TravelAgency'
import Consulting from '../views/home-pages/Consulting'
import SeoAgency from '../views/home-pages/SeoAgency'
import PersonalPortfolio from '../views/home-pages/PersonalPortfolio'
import EventConference from '../views/home-pages/EventConference'
import CreativePortfolio from '../views/home-pages/CreativePortfolio'
import Freelancer from '../views/home-pages/Freelancer'
import InternationalConsulting from '../views/home-pages/InternationalConsulting'
import Startup from '../views/home-pages/Startup'
import WebAgency from '../views/home-pages/WebAgency'
import YesoProduct from '../views/product.vue'
import YesoService from '../views/giveService.vue'
import YesoExample from '../views/example.vue'
import AboutDetial from '../views/aboutDetial.vue'
import ProductDetail from '../views/productDetail.vue'
import ExplainPage from '../views/expain.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Demo',
        component: Demo,
        redirect: '/home',
        meta: {
            title: '上海华夜信息',
        },
    },
    // {
    //     path: '/button',
    //     name: 'Button',
    //     component: ButtonPage,
    //     meta: {
    //         title: 'Button || Doob Business and Consulting Bootstrap5 Template',
    //     },
    // },
    {
        path: '/home',
        name: 'Business Consulting',
        component: BusinessConsulting2,
        meta: {
            title: '首页 | 上海华夜信息科技有限公司',
        },
    },
    {
        path: '/product',
        name: 'Product',
        component: YesoProduct,
        meta: {
            title: '产品中心 | 上海华夜信息科技有限公司',
        },
    },
    {
        path: '/productDetail',
        name: 'About',
        component: ProductDetail,
        meta: {
            title: '产品详情 | 上海华夜信息科技有限公司',
        },
    },
    {
        path: '/service',
        name: 'Service',
        component: YesoService,
        meta: {
            title: '提供服务 | 上海华夜信息科技有限公司',
        },
    },
    {
        path: '/example',
        name: 'example',
        component: YesoExample,
        meta: {
            title: '经典案例 | 上海华夜信息科技有限公司',
        },
    },
    
    {
        path: '/about',
        name: 'About',
        component: About,
        meta: {
            title: '关于 | 上海华夜信息科技有限公司',
        },
    },
    {
        path: '/abouts/:id',
        name: 'About',
        component: AboutDetial,
        meta: {
            title: '技术详情 | 上海华夜信息科技有限公司',
        },
    },
    
    {
        path: '/news',
        name: 'Blog list',
        component: BlogListPage,
        meta: {
            title: '新闻中心  | 上海华夜信息科技有限公司',
        },
    },
    {
        path: '/newsdetail/:id',
        name: 'detail',
        component: BlogDetailsPage,
        meta: {
            title: '新闻详情 | 上海华夜信息科技有限公司',
        },
    },
    {
        path: '/contact',
        name: 'contact',
        component: ContactPage,
        meta: {
            title: '招贤纳士  | 上海华夜信息科技有限公司',
        },
    },
    {
        path: '/explain',
        name: 'explain',
        component: ExplainPage,
        meta: {
            title: '法律声明  | 上海华夜信息科技有限公司',
        },
    },
    
    
]

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    window.scrollTo(0, 0)
    next();
    
});

export default router
